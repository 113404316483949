import React from 'react'

import { Store } from 'shared-libs/models/store'

import 'browser/app/pages/app/entity/_entities.scss'

import apis, { PubSubMessage } from 'browser/app/models/apis'
import ComponentsMap from 'browser/components'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'
import { browserHistory } from 'browser/history'
import { EntityRenderer } from 'shared-libs/components/entity/renderer'
import { AppNavigatorContext } from 'browser/contexts/app-navigator/app-navigator-context'
import { Hotkey, Hotkeys, HotkeysTarget } from '@blueprintjs/core'
import { EdgeDetails } from 'shared-libs/models/entity'
import _ from 'lodash'
import { EntitySubscriptionManager } from 'browser/app/utils/entity-subscription-manager'
import { stringToBoolean } from 'browser/app/utils/utils'

interface IEntityPageProps {
  context?: any
  entity?: any
  history: any
  isFullScreen?: boolean
  location: any
  match: any
  navigateToPrevPage?: any
  navigateToNextPage?: any
  navigateToPage?: any
  onClose?: () => void
  rendererState?: any
  uiSchemaPath?: string
}

@HotkeysTarget
export class EntityPage extends React.Component<IEntityPageProps, any> {
  private edges: string[] = []

  public static defaultProps: Partial<IEntityPageProps> = {
    uiSchemaPath: 'uiSchema.web.entityDetailCard',
  }

  private store: Store

  constructor(props: IEntityPageProps) {
    super(props)
    this.store = apis.getStore()
    this.state = {
      entity: props.entity,
      isLoading: !props.entity,
    }
    this.subscribeToUpdates()
  }

  public componentDidMount(): void {
    const entityId = this.props.match?.params?.entityId
    window.addEventListener('beforeunload', this.componentWillUnmount.bind(this))
    if (!this.state.entity && entityId) {
      this.fetchModels(entityId)
        .then(() => this.subscribeToUpdates())
    }
  }

  public componentWillUnmount(): void {
    const updatesEnabled = stringToBoolean(apis.getSettings().getRemoteConfigValue('entityUpdates'))
    if (updatesEnabled) {
      EntitySubscriptionManager.removeEntityUpdateSubscriptions(this.edges)
      EntitySubscriptionManager.removeEntityUpdateListener(this.handlePubsubMessage)
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps): void {
    // if there is no entity in the props, and entityId is different
    const entityId = this.props.match?.params?.entityId
    const nextEntityId = nextProps.match?.params?.entityId
    if (nextProps.entity) {
      this.setState({ entity: nextProps.entity })
      this.subscribeToUpdates()
    } else if (nextEntityId && entityId !== nextEntityId) {
      this.fetchModels(nextEntityId)
        .then(() => this.subscribeToUpdates())
    }
  }

  public render(): JSX.Element {
    if (this.state.isLoading) {
      return <LoadingSpinner />
    }
    const {
      history,
      location,
      isFullScreen,
      match,
      navigateToPrevPage,
      navigateToNextPage,
      navigateToPage,
      rendererState,
      uiSchemaPath,
    } = this.props
    const { entity } = this.state
    const entityRenderState = {
      history,
      location,
      match,
      ...rendererState,
    }
    return (
      <AppNavigatorContext.Consumer>
        {({settings}) => (
          <EntityRenderer
            actions={{ onClose: this.handleClose }}
            componentsMap={ComponentsMap}
            context={{
              density: 'collapse',
              isFullScreen,
              isHorizontalLayout: true,
              location,
              navigateToNextPage,
              navigateToPage,
              navigateToPrevPage,
            }}
            state={{...entityRenderState, settings}}
            uiSchemaPath={uiSchemaPath}
            value={entity}
          />
        )}
      </AppNavigatorContext.Consumer>
    )
  }

  public renderHotkeys(): JSX.Element {
    return (
      <Hotkeys>
        <Hotkey
          allowInInput={true}
          combo="ctrl+/"
          label="Insights"
          onKeyDown={this.goToInsights}
          stopPropagation={true}
          preventDefault={true}
          global={true}
        />
      </Hotkeys>
    )
  }

  private goToInsights = () => {
    const { history, match } = this.props
    history?.push(`/insights/${match?.params?.entityId}`)
  }

  private fetchModels(entityId) {
    this.setState({ isLoading: true })
    return this.store.findRecord(entityId).then((entity) => {
      this.setState({ entity, isLoading: false })
    }).catch((error) => { throw error })
  }

  private async subscribeToUpdates() {
    const updatesEnabled = stringToBoolean(apis.getSettings().getRemoteConfigValue('entityUpdates'))
    if (!updatesEnabled) return
    const { entity } = this.state
    if (!entity) return
    const edges = _.map(entity.getEdges?.(), (edge: EdgeDetails) => edge.value?.entityId)
    try {
      await EntitySubscriptionManager.diffEntitySubscriptions(this.edges, edges, this.handlePubsubMessage)
    } catch(err) {
      console.log(`failed to update subscriptions: ${err.stack}`)
    }
    this.edges = edges
  }

  private handlePubsubMessage = async (e: PubSubMessage) => {
    const uri = _.get(e, 'data')
    if (!uri) {
      return
    }
    try {
      const parsedUri = new URL(uri)
      if (parsedUri.pathname !== '/actions/entity/update') {
        return
      }
      // Mobile and web seem to present the notification differently
      const json = parsedUri.searchParams.get('jsonProps').replace(/\\/g,'')
      const payload = JSON.parse(json)
      const { entity } = this.state
      await Promise.all([entity.reload(), ..._.map(payload.updatedEntities, async (updated) => {
        if (entity.uniqueId === updated) {
          return Promise.resolve()
        }
        const updatedEntity = apis.getStore().getRecord(updated)
        if (_.isNil(updatedEntity)) {
          return apis.getStore().getOrFetchRecord(updated)
        } else {
          return updatedEntity.reload()
        }
      })])
      this.forceUpdate()
    } catch (err) {
      console.warn(`failed to parse notification: ${err.message}\n${err.stack}`)
    }
  }

  private handleClose = () => {
    const { onClose } = this.props
    if (onClose) {
      return onClose()
    }
    browserHistory.push({ pathname: '/' })
  }
}
