/**
 * Creates an Error with additional context properties, useful in cases where
 * api methods would reject with the http library's raw non-error object instead
 * of an Error object. Ensuring that we reject with a genuine Error helps Sentry
 * log the stack trace properly so we can better debug where the unhandled
 * rejections are happening and decide how to handle.
 */
export function ensureError(message: any, context: any) {
  const error = new Error(message)

  // only apply context if it's a proper object
  if (context && typeof context === 'object' && !Array.isArray(context)) {
    // preserve the original properties for backward compatibility
    Object.assign(error, context)
  } else if (context != null) {
    // store non-object context in a safe property
    error['contextValue'] = context
  }

  return error
}

/**
 * Extract the useful information from the jquery XHR object.
 */
export function extractJqueryContext(jqXHR: any) {
  return {
    url: jqXHR.url,
    status: jqXHR.status,
    readyState: jqXHR.readyState,
    statusText: jqXHR.statusText,
    responseText: jqXHR.responseText,
  }
}
