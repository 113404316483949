import _, { Dictionary, ValueIteratee } from 'lodash'
import moment from 'moment-timezone'
import VALID_TLDS from './validation/valid-email-tlds'
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js'
import { FormulaRegistry } from './formulas'
import { translateString as baseTranslate } from './formulas/modules/translation'
import { Geolocation } from '../generated/server-types/entity/address'
import { Promise as BPromise } from 'bluebird'

const DEFAULT_HEALTH_STATUS_NAME = 'withvector-internal'
const DEFAULT_HOSTS = ['https://api.withvector.com', 'https://api.loaddocs.co']
const STAGING_HOST = 'https://api.withvector-staging.com'
const STAGING_CLOUD_ACCOUNT = 'withvector6'
const PRODUCTION_CLOUD_ACCOUNT = 'withvector-production'

// https://github.com/epoberezkin/ajv/blob/master/lib/compile/formats.js

const EmailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
const EMAIL_TLD_REGEX = /(?:\.)([a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/i
const UUIDRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
const VECTOR_DOMAIN_REGEX = /(^|\.)(withvector(\d+|-prod|-staging)?.com|localhost)$/i

// From https://stackoverflow.com/a/31408260
const GEOLOCATION_VALIDATION_PATTERN = /^(\+|-)?(?:90(?:(?:\.0{1,8})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,8})?)), *(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,8})?))$/
// a simpler pattern for extracting geocoordinates (the validation pattern
// doesn't work for this)
const GEOLOCATION_EXTRACTION_PATTERN = /((\+|-)?\d+(\.\d+?)),\s*((\+|-)?\d+(\.\d+)?)/

const DEFAULT_LOCALE = 'en_US'

// determines if mixin array contains an element identified in the set
export function hasMixinByIds(mixins, idSet) {
  if (!mixins) {
    return false
  }
  let i
  for (i = 0; i < mixins.length; i++) {
    if (idSet.has(mixins[i].entityId)) return true
  }
  return false
}

// checks if given email is valid based on RFC 2822
export function isEmailValid(email) {
  return !_.isEmpty(email) && EmailRegex.test(email) && hasValidTLD(email)
}

export function jsonCloneDeep(jsonValidObj) {
  if (jsonValidObj === undefined) { return undefined }
  return JSON.parse(JSON.stringify(jsonValidObj))
}

function hasValidTLD(email: string) {
  // extract the TLD and ensure it's valid
  const match = email.match(EMAIL_TLD_REGEX)
  const domain = _.toUpper(_.get(match, 1))
  return VALID_TLDS.has(domain)
}

/**
 * Insert CDN size parameter into an image url, defaulting to a small thumbnail size.
 */
export function resizeImageUri(uri: string, width?: number, height?: number): string {
  width = _.isInteger(width) ? width : 64
  height = _.isInteger(height) ? height : 64

  let url: URL
  try {
    url = new URL(uri)
  } catch (error) {
    // gracefully fail back to original uri if URL can't make sense of `uri` for
    // some reason.
    return uri
  }

  const pathParts = _.split(url.pathname, '/')

  if (pathParts[1] === 'fit-in') {
    // replace
    pathParts[2] = `${width}x${height}`
  } else {
    // insert
    pathParts.splice(1, 0, `fit-in/${width}x${height}`)
  }

  url.pathname = _.join(pathParts, '/')

  return url.toString()
}

export function isValidVectorHostname(hostname?: string): boolean {
  return VECTOR_DOMAIN_REGEX.test(hostname)
}

export function isPhoneValid(phone) {
  if (_.isEmpty(phone)) {
    return false
  }

  const parsedPhoneNumber = parsePhoneNumberFromString(phone, 'US')
  return parsedPhoneNumber && parsedPhoneNumber.isValid()
}

export function parsePhoneNumber(phone, defaultCountryCode: CountryCode = 'US') {
  return parsePhoneNumberFromString(phone, defaultCountryCode)
}

export function isUUIDValid(uuid) {
  return UUIDRegex.test(uuid)
}

/**
 * @param userInfo - contact info string [email|phone|uuid]
 *
 * @returns deferredUser object
 * "/1.0/entities/metadata/entity.json#/definitions/deferredUser"
 */
export function getDeferredUser(userInfo: string) {
  let contact = {}
  if (isEmailValid(userInfo)) {
    contact = { email: userInfo }
  } else if (isPhoneValid(userInfo)) {
    const parsedPhoneNumber = parsePhoneNumberFromString(userInfo, 'US')
    const phone = parsedPhoneNumber.number
    contact = { phoneNumber: { phone } }
  } else if (isUUIDValid(userInfo)) {
    contact = { user: { entityId: userInfo } }
  }
  return contact
}

export function convertTimezone(dateTime, timezone, newTimezone) {
  dateTime = moment.tz(dateTime, moment.ISO_8601, timezone)
  const values = dateTime.toArray()
  const newDateTime = moment.tz(newTimezone)
  newDateTime
    .year(values[0])
    .month(values[1])
    .date(values[2])
    .hours(values[3])
    .minutes(values[4])
    .seconds(values[5])
    .milliseconds(values[6])
  return newDateTime.toISOString()
}

export function formatZonedDate(dateTime, format, tz) {
  return moment(dateTime).tz(tz).format(format)
}

/**
 * Initializes the timezone in moment-timezone.
 *
 * Moved here from front-end code, since module resolution/caching doesn't prevent
 * two separate node apps (e.g. mobile and shared-libs) from initializing
 * a separate instance of a module. The module name alone doesn't make them the
 * same module - they're resolved from each app's node_modules separately.
 *
 * See https://nodejs.org/api/modules.html#modules_module_caching_caveats
 *
 * @param tzName A zoneinfo-style timezone name, like "America/Los_Angeles"
 */
export function initializeTimezone(tzName: string): void {
  moment.tz.setDefault(tzName)
}

export function makeCancellablePromise(promise) {
  return new BPromise((resolve, reject, onCancel) => {
    let isCanceled = false
    onCancel(() => (isCanceled = true))
    promise
      .then((...response) => {
        if (!isCanceled) {
          resolve(...response)
        }
      })
      .catch((...error) => {
        if (!isCanceled) {
          reject(...error)
        }
      })
  })
}

/**
 * Extracts a map containing a URL's query string params.
 */
export function extractQueryStringParameters(url: string, delim = '&'): Dictionary<string> {
  return _.chain(url)
    .replace(/^.+\?/, '')
    .split(delim)
    .map((str) => _.split(str, '=', 2))
    .fromPairs()
    .value()
}

/**
 * Deep diff between two objects, based on
 * https://github.com/mattphillips/deep-object-diff/blob/master/src/diff/index.js
 */
const properObject = (o) => (_.isObject(o) && !o.hasOwnProperty ? { ...o } : o)
export function objectDiff(lhs, rhs) {
  if (lhs === rhs) return {} // equal return no diff

  if (!_.isObject(lhs) || !_.isObject(rhs)) return rhs // return updated rhs

  const l = properObject(lhs)
  const r = properObject(rhs)

  const deletedValues = Object.keys(l).reduce((acc, key) => {
    return r.hasOwnProperty(key) ? acc : { ...acc, [key]: undefined }
  }, {})

  if (_.isDate(l) || _.isDate(r)) {
    if (l.valueOf() == r.valueOf()) return {}
    return r
  }

  return Object.keys(r).reduce((acc, key) => {
    if (!l.hasOwnProperty(key)) return { ...acc, [key]: r[key] } // return added r key

    const difference = objectDiff(l[key], r[key])

    if (_.isObject(difference) && _.isEmpty(difference) && !_.isDate(difference)) return acc // return no diff

    return { ...acc, [key]: difference } // return updated key
  }, deletedValues)
}

/**
 * Searches a uiSchema's `children` arrays recursively for an object that satisfies a predicate.
 * @param obj A schema json object.
 * @param value A search predicate or iteratee.
 */
export function deepFind(obj, value) {
  if (_.isEmpty(obj)) {
    return null
  }
  let found = _.find([obj], value)
  if (!_.isEmpty(found)) {
    return found
  }

  for (const child of _.get(obj, 'children', [])) {
    found = deepFind(child, value)
    if (!_.isEmpty(found)) {
      break
    }
  }
  return found
}


/**
 * A more generic deeply find
 */
export function deeplyFind(obj, value) {
  if (_.isEmpty(obj)) {
    return null
  }
  let found = _.find([obj], value)

  if (!_.isEmpty(found) || !_.isObject(obj)) {
    return found
  }

  found = _.find(obj, (child) => deeplyFind(child, value))

  return found
}

/**
 * Searches an object recursively for another object and gives the full path to it.
 * @param obj A source object.
 * @param other A value to search for.
 * @return The path within `obj` to the matching `other`.
 */
export function deepPathFind(obj, other): string[] {
  const paths = []
  const predicate = _.isFunction(other) ? other : undefined
  for (const key in obj) {
    const value = obj[key]
    if (value === other || (predicate && predicate(value))) {
      paths.push(key)
    } else if (_.isObject(value)) {
      const childPaths = deepPathFind(value, other)
      childPaths.forEach((path) => {
        paths.push(_.join([key, path], '.'))
      })
    }
  }
  return paths
}

/**
 * Invoke a function only after `n` invocations.
 *
 * Similar to lodash `_.after`, but makes it periodic so that the function
 * doesn't always get called on all subsequent invocations.
 */
export function invokeOnceEvery(n: number, func: Function): Function {
  let i = 0
  n = _.toInteger(n)
  return (): any => {
    if (++i % n === 0) {
      return func.apply(func)
    }
  }
}

/**
 * Inserts an object into an array in order, given an iteratee for comparison.
 */
export function insertInOrder<T>(arr: T[], child: T, iteratee: ValueIteratee<T> = _.identity) {
  const index = _.sortedIndexBy(arr, child, iteratee)
  arr.splice(index, 0, child)
}

/**
 * standalone url parser from https://stackoverflow.com/questions/736513/how-do-i-parse-a-url-into-hostname-and-path-in-javascript
 */
export function urlParser(url?: string) {
  if (!url) {
    return
  }
  const match = url.match(
    /^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
  )
  return (
    match && {
      href: url,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7],
    }
  )
}

export function getHealthStatusUrl(host: string): string {
  let hostname = DEFAULT_HEALTH_STATUS_NAME
  if (DEFAULT_HOSTS.indexOf(host) === -1) {
    const parsedUrl = urlParser(host)
    hostname = parsedUrl && parsedUrl.hostname ? parsedUrl.hostname : DEFAULT_HEALTH_STATUS_NAME
  }

  // this does not handle all possible host name like a.b.c.d.e
  // it only handle the host name from host selector in the format api.<host>.com
  const components = hostname.split('.')
  const healthName = components.length == 1 ? components[0] : components[1]

  return `https://cdn.loaddocs.co/health/${healthName}.json`
}

export function isProductionApi(host: string): boolean {
  return DEFAULT_HOSTS.indexOf(host) >= 0
}

/**
 * Maps an api host url to a friendly alias string.
 */
export function getEnvironmentAlias(host: string): string {
  if (/api.withvector.com/.test(host) || /api.loaddocs.co/.test(host)) {
    return 'production'
  }

  if (/localhost/.test(host)) {
    return 'localhost'
  }

  let match = host?.match(/api.withvector-(\w+)/)
  if (match?.[1]) {
    return match[1]
  }

  match = host?.match(/api.withvector(\d+)/)
  if (match?.[1]) {
    return `wv${match[1]}`
  }

  return host
}

export function getCloudAccountFromApi(host: string): string {
  if (DEFAULT_HOSTS.indexOf(host) >= 0) {
    return PRODUCTION_CLOUD_ACCOUNT
  } else if (host === STAGING_HOST) {
    return STAGING_CLOUD_ACCOUNT
  } else {
    const hostParts = _.split(host, '.')
    // e.g. https://api.withvector5.com -> withvector5
    return hostParts[1]
  }
}

export function getComponentTranslationTable(initialTable: any = {}, entities: any[] = []) {
  return _.reduce(
    entities,
    (translations: any, schema: any) => {
      return _.merge({}, translations, schema?.translationTable)
    },
    initialTable
  )
}

export function translateString(
  source: string | undefined,
  translations: any,
  context?: any
): string {
  return baseTranslate(
    source,
    translations,
    FormulaRegistry.getApplicationContext().getLocale?.(),
    context
  )
}

export function getLocale() {
  return FormulaRegistry.getApplicationContext().getLocale?.() || DEFAULT_LOCALE
}

export function parseGeolocation(userInput: string): Geolocation | undefined {
  if (!userInput) {
    return undefined
  }

  if (!GEOLOCATION_VALIDATION_PATTERN.test(userInput)) {
    throw new Error('Invalid format')
  }

  const match = userInput.match(GEOLOCATION_EXTRACTION_PATTERN)
  if (!match) {
    throw new Error('Failed to parse coordinates')
  }

  const [, lat, , , lng, , ] = match
  if (!lat || !lng) {
    throw new Error('Failed to extract lat/lng values')
  }

  return { latitude: parseFloat(lat), longitude: parseFloat(lng) }
}

export function Try<T>(fn: () => T, err?: (e: Error) => any): T {
  try {
    return fn()
  } catch (e) {
    return err?.(e)
  }
}

export async function TryAsync<T>(fn: () => BPromise<T>, err?: (e: Error) => any): BPromise<T> {
  try {
    return await fn()
  } catch (e) {
    return err?.(e)
  }
}

export function isReactElement(value: any): boolean {
  return value?.$$typeof === Symbol.for('react.element')
}

export async function waitUntil(
  condition: () => boolean,
  intervalMs: number = 100,
  maxAttempts: number = 2,
): BPromise<boolean> {
  let attempts = 0;

  while (attempts < maxAttempts) {
    if (condition()) {
      return true
    }
    attempts++
    void await sleep(intervalMs)
  }

  return false
}

export async function sleep(ms) {
  return new BPromise(resolve => _.delay(resolve, ms))
}
